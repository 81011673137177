let store = window.FLEET_CONFIG || {};

function setConfig(param) {
    store = param || store || {};
}

function get(param) {
    return store[param];
}

const Configuration = {
    setConfig,
    get,
};

export default Configuration;

// RECORDS_PER_PAGE value can be 10, 20, 30
export const RECORDS_PER_PAGE = 30;
export const ROWS_PER_PAGE_OPTIONS = [10, 20, 30, 50, 100];
// This for cluster map Page
export const MAX_PRECISION_VALUE = 12;
export const DISPATCH_EMAIL_ID = '<fleet.dispatch@spireon.com>';
export const SEARCH_CHAR_LIMIT = 50;
export const TRIP_DIRECTIONS_CACHE_LIMIT = 2500;
export const MIX_PANEL_APP_TYPE = 'FleetPWA';
export const LOCAL_MIX_PANEL_APP_TYPE = 'LocalFleet';
export const NON_REPORTING_DAYS = {
    Moving: 1,
    Stopped: 2,
    Idle: 1,
};
export const LIVE_VIDEO_AUTO_PAUSE_DURATION = 30000;
export const DISABLE_ICON_OPACITY = 0.63;
export const ICON_OPACITY = 1;
export const MIN_SEARCH_LENGTH = 3;
export const FVS_MAX_DATA_LIMIT = 200;
export const LANDMARK_GROUP_DB_ID_MAX_DATA_LIMIT = 50;
export const PASSWORD_MIN_LENGTH = 8;
export const FIELD_MAX_LENGTH_255 = 255;
export const USERNAME_MAX_LENGTH = 100;
export const PASSWORD_REGEX = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9#?!@$%^&*-])(?!.*?[+]).{8,255}$/;
export const MSG_PASSWORD_STRENGTH = ['8 characters is minimum', 'At least 1 upper case and 1 lower case', 'At least 1 number or 1 special character (one of #?!@$%^&*-)'];
export const PASSWORD_VALIDATION_FAILED = 'Please enter valid password';
export const ZOOM_LEVEL_FOUR = 4;
export const AUTH_LOGIN_URL = '/auth/login?';
export const IMPERSONATION_LOGIN_URL = '/auth/tokenlogin?';
